import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

export const DownloadAppContent = () => {
  const t = useTranslations('common');
  return (
    <div className="text-center p-4 max-w-md">
      <h2 className="font-bold text-3xl">
        {t('DownloadAppContent.title')}
      </h2>
      <p className="text-base my-4">{t('DownloadAppContent.subtitle')}</p>
      <Image
        src={IMGPW + '/qr_download_app.png'}
        height={180}
        width={180}
        alt={t('DownloadAppContent.title')}
        className={'mx-auto'}
      />
      <div className="flex space-x-4"></div>
    </div>
  );
};
