import { useLocalization } from '@/hooks/useLocalization';
import { useTranslations } from 'next-intl';
import {
  ChevronRight as IconChevronRight,
  Location as IconLocation,
} from '../../../../../../../components/icons';
import { ILocation } from '../../../../../../../models/location';

interface Props {
  onSelect: (location: ILocation) => void;
}

export default function LastAddress({ onSelect }: Props) {
  const t = useTranslations('common');
  const { location } = useLocalization();

  if (!location || !location.formattedAddress) {
    return null;
  }

  return (
    <div className="p-4 space-y-2">
      <h5 className="text-lg text-greyscale-title font-bold tracking-tighter m-0">
        {t('address.last')}
      </h5>
      <ul className="divide-y divide-greyscale-separator m-0 p-0">
        <li
          className="cursor-pointer hover:bg-greyscale-background flex items-center gap-2 py-2 -mx-4 px-4 text-greyscale-body"
          onClick={() => onSelect(location)}
        >
          <IconLocation />
          <div className="flex-1 text-base tracking-tight font-medium leading-5 truncate">
            {location.formattedAddress}
          </div>
          <IconChevronRight className="shrink-0 h-5" />
        </li>
      </ul>
    </div>
  );
}
