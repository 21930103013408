import AddressSelect from '@/app/[locale]/shop/components/commons/AddressModal/AddressSelect';
import {Button, Input} from '@/components/design-system';
import {Close as IconClose, Location as IconLocation} from '@/components/icons';
import {ILocation} from '@/models/location';
import {useTranslations} from 'next-intl';
import {HTMLAttributes, useState} from 'react';

interface Props {
  onChangeAddress: (location: ILocation) => void;
  formClassName?: HTMLAttributes<HTMLFormElement>['className'];
  suggestionsClassName?: HTMLAttributes<HTMLDivElement>['className'];
  bodyLoggedMessage?: boolean;
  onChangeQ?: (q: string) => void;
}

export default function AddressInput({
  onChangeAddress,
  formClassName,
  suggestionsClassName,
  bodyLoggedMessage = true,
  onChangeQ,
}: Props) {
  const [q, setQ] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const t = useTranslations('common');

  return (
    <div className="relative w-full">
      <form
        className={`${
          formClassName || ''
        } flex items-center border-greyscale-separator shrink-0`}
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          className="w-full"
          value={q}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 100)}
          onChange={(e) => {
            setQ(e.target.value);
            onChangeQ && onChangeQ(q);
          }}
          placeholder={t('address.insert')}
          leftIcon={
            <div className="shrink-0">
              <IconLocation className="text-greyscale-placeholder" />
            </div>
          }
          rightIcon={
            <Button
              className="shrink-0"
              onClick={(e) => {
                e.preventDefault();
                setQ('');
                onChangeQ && onChangeQ(q);
              }}
              palette="gray"
              theme="ghost"
              type="button"
              variant="icon"
            >
              <IconClose className="" />
            </Button>
          }
        />
        <Button
          className="border border-transparent ml-2 px-6 "
          type="submit"
        >
          {t('AddressBar.go')}
        </Button>
      </form>
      <div
        className={`${suggestionsClassName} flex-1 shadow-md overflow-y-auto max-h-96`}
      >
        <AddressSelect
          q={q}
          bodyLoggedMessage={bodyLoggedMessage}
          showSavedAddresses={true}
          showLastAddress={true}
          onChangeQ={(q) => {
            setQ(q);
            onChangeQ && onChangeQ(q);
          }}
          onChangeAddress={(location) => {
            onChangeAddress(location);
          }}
          isFocused={isFocused}
        />
      </div>
    </div>
  );
}
