import { useTranslations } from 'next-intl';
import {
  ChevronRight as IconChevronRight,
  Location as IconLocation,
} from '../../../../../../../components/icons';
import { ILocation } from '../../../../../../../models/location';
import useSession from '../../../../../../../stores/session/useSession';

interface Props {
  onSelect: (location: ILocation) => void;
}

export default function SavedAddresses({ onSelect }: Props) {
  const t = useTranslations('common');
  const { user } = useSession();

  if (!user || !user?.addresses || user.addresses.length === 0) {
    return null;
  }

  return (
    <div className="p-4 space-y-2">
      <h5 className="text-lg text-greyscale-title font-bold tracking-tighter m-0">
        {t('address.saved')}
      </h5>
      <ul className="divide-y divide-greyscale-separator m-0 p-0">
        {user?.addresses?.map((address) => (
          <li
            key={address._id}
            className="cursor-pointer hover:bg-greyscale-background flex items-center gap-2 py-2 -mx-4 px-4 text-greyscale-body"
            onClick={() => {
              onSelect({
                formattedAddress: address.formattedAddress,
                city: address.city!,
                address: address.address!,
                postalCode: '',
                streetNumber: address.num,
                coordinates: {
                  lat: address.latitude,
                  lng: address.longitude,
                },
                label: address.label,
              });
            }}
          >
            <IconLocation />
            <div className="flex-1 text-base tracking-tight">
              {!!address.label ? (
                <div className="font-bold leading-5 truncate">
                  {address.label}
                </div>
              ) : null}
              <div className="font-medium leading-5 truncate">
                {address.formattedAddress}
              </div>
            </div>
            <IconChevronRight className="shrink-0 h-5" />
          </li>
        ))}
      </ul>
    </div>
  );
}
