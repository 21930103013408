import { Button } from '../../../../../components/design-system';
import { Minus, Plus } from '../../../../../components/icons';
import { useLocalization } from '../../../../../hooks/useLocalization';

interface Props {
  disabled?: boolean;
  max?: number;
  quantity?: number;
  size?: 'sm' | 'md' | 'lg';
  onAdd: () => void;
  onSub: () => void;
}

function QuantityManager({
  disabled = false,
  max,
  quantity = 0,
  size = 'md',
  onAdd,
  onSub,
}: Props) {
  const localization = useLocalization();

  return (
    <div className="flex items-center">
      {quantity > 0 && (
        <>
          <Button
            rounded
            size={size}
            theme="outline"
            variant="icon"
            onClick={(e) => {
              e.preventDefault();
              onSub();
            }}
          >
            <Minus />
          </Button>
          <span
            className={`
            w-8 text-center text-base text-greyscale-body font-medium tracking-tight
            ${
              size === 'lg'
                ? 'text-lg'
                : size === 'sm'
                ? 'text-sm'
                : 'text-base'
            }
          `}
          >
            {quantity}
          </span>
        </>
      )}
      {localization.az && (
        <Button
          rounded
          size={size}
          theme="outline"
          variant="icon"
          onClick={(e) => {
            e.preventDefault();
            onAdd();
          }}
        >
          <Plus />
        </Button>
      )}
    </div>
  );
}

export default QuantityManager;
