'use client';

import * as Targeting360 from '@/lib/targeting360';
import animateScrollTo from 'animated-scroll-to';
import { useEffect, useState } from 'react';
import BrandSection from './components/BrandSection';
import CategorySection from './components/CategorySection';
import CitiesSection from './components/CitiesSection';
import DownloadAppSection from './components/DownloadAppSection';
import FooterSection from './components/FooterSection';
import MainSection from './components/MainSection';
import StepsSection from './components/StepsSection';
import TestimonialSection from './components/TestimonialSection';
import WhatIsSection from './components/WhatIsSection';

interface Props {
  cities: { name: string }[];
  results?: any;
}

export default function HomeContent(props: Props) {
  useEffect(() => {
    Targeting360.trackHomePage();
  }, []);

  const [state, setState] = useState<{
    highlightedCity: string;
  }>({
    highlightedCity: '',
  });

  const handleCitySelect = (highlightedCity: string) => {
    setState({ highlightedCity });
    animateScrollTo(0, { speed: 1500 });
  };

  return (
    <>
      <MainSection highlightedCity={state.highlightedCity} />
      <StepsSection />
      <WhatIsSection />
      <CategorySection />
      <DownloadAppSection />
      <BrandSection platinum={props.results.partners || []} />
      <TestimonialSection />
      <FooterSection />
      <CitiesSection cities={props.cities} onCitySelect={handleCitySelect} />
      <div id="targeting-360-div" />
    </>
  );
}
