'use client';
import DownloadAppButton from 'src/components/design-system/DownloadAppButton';
import { Button } from '@/components/design-system';
import { usePathname } from '@/navigation';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { BrowserView } from 'react-device-detect';

export default function BannerDownloadApp() {
  const pathname = usePathname();
  const t = useTranslations('common');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  if (pathname !== '/' || !show) {
    return null;
  }

  return (
    <BrowserView>
      <div className="hidden md:block bg-secondary">
        <div className="flex items-center justify-center space-x-4 py-2">
          <div className="text-sm text-white font-medium">
            {t('DownloadAppContent.title')}
          </div>
          <div className="flex items-center">
            <DownloadAppButton
              renderCustomDesktopButton={(onClick) => (
                <Button
                  palette="white"
                  theme="outline"
                  size="sm"
                  onClick={onClick}
                >
                  <i className="socicon-apple group-hover:text-secondary"></i>
                  <i className="socicon-android group-hover:text-secondary"></i>
                  <span className="group-hover:text-secondary text-xs">
                    {t("DownloadAppContent.Scarica l'app!")}
                  </span>
                </Button>
              )}
            />
          </div>
        </div>
      </div>
    </BrowserView>
  );
}
