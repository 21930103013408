import { useTranslations } from 'next-intl';
import DownloadAppButton from 'src/components/design-system/DownloadAppButton';

export default function DownloadAppSection() {
  const t = useTranslations('index');

  return (
    <section className="bg-greyscale-gray-lighter">
      <div className="section-container">
        <div
          className={`flex justify-center bg-no-repeat bg-right bg-contain lg:bg-download-app`}
        >
          <div className="lg:w-1/2 w-2/3">
            <div className="py-36 text-center lg:text-left">
              <h2 className="text-4xl font-bold tracking-tight text-greyscale-title mb-2">
                {t('DownloadAppSection.download-app')}
              </h2>
              <p className="text-base font-medium tracking-tight text-greyscale-label p-0">
                {t('DownloadAppSection.download-app-desc')}
              </p>
              <div className="mt-5">
                <DownloadAppButton />
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-1/3 hidden lg:block" />
        </div>
      </div>
    </section>
  );
}
