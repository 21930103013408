import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

export default function StepsSection() {
  const t = useTranslations('index');

  return (
    <section className="mt-6">
      <div className="section-container">
        <h2 className="text-3xl font-bold tracking-tight text-greyscale-title text-center mb-2">
          {t('StepsSection.three-steps')}
        </h2>
        <div className="flex flex-wrap gap-8 justify-center">
          <div className="sm:w-1/4">
            <div className="text-center">
              <Image
                width={0}
                height={220}
                sizes="100vw"
                style={{width: 'auto', margin: '0 auto'}}
                src={`${IMGPW}/1st_step_home.jpg`}
                alt="Location"
              />
              <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-2 p-0">
                {t('StepsSection.insert-address')}
              </h4>
              <p className="text-base font-medium tracking-tight text-greyscale-label mb-0 p-0">
                {t('StepsSection.insert-address-desc')}
              </p>
            </div>
          </div>
          <div className="sm:w-1/4">
            <div className="text-center">
              <Image
                width={0}
                height={220}
                sizes="100vw"
                style={{width: 'auto', margin: '0 auto'}}
                src={`${IMGPW}/2nd_step_home.jpg`}
                alt="Scelta vino"
              />
              <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-2 p-0">
                {t('StepsSection.choose-your-favourite')}
              </h4>
              <p className="text-base font-medium tracking-tight text-greyscale-label mb-0 p-0">
                {t('StepsSection.choose-your-favourite-desc')}
              </p>
            </div>
          </div>
          <div className="sm:w-1/4">
            <div className="text-center">
              <Image
                width={0}
                height={220}
                sizes="100vw"
                style={{width: 'auto', margin: '0 auto'}}
                src={`${IMGPW}/3rd_step_home.jpg`}
                alt="Relax"
              />
              <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-2 p-0">
                {t('StepsSection.relax')}
              </h4>
              <p className="text-base font-medium tracking-tight text-greyscale-label mb-0 p-0">
                {t('StepsSection.relax-desc')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
