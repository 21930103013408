import { useState } from 'react';
import { Button, Input } from '../../../../../../../components/design-system';
import { ArrowRight as IconArrowRight } from '../../../../../../../components/icons';
import { useTranslations } from 'next-intl';

interface Props {
  onSubmit: (streetNumber: string) => void;
}

export default function StreetNumberForm({ onSubmit }: Props) {
  const t = useTranslations('common');
  const [streetNumber, setStreetNumber] = useState('');

  return (
    <div>
      <h5 className="text-lg text-greyscale-title font-bold tracking-tighter mb-3">
        {t('address.streetNumber')}
      </h5>
      <form
        className="flex items-center gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(streetNumber);
        }}
      >
        <Input
          className="flex-1"
          autoFocus
          value={streetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
        <Button
          palette="primary"
          type="submit"
          variant="icon"
          disabled={streetNumber.trim().length === 0}
        >
          <IconArrowRight />
        </Button>
      </form>
    </div>
  );
}
