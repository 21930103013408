import QuantityManager from '@/app/[locale]/shop/components/commons/QuantityManager';
import useProduct from '@/app/[locale]/shop/components/hooks/useProduct';
import {SearchProduct} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import {useFormatter} from 'next-intl';
import Image from 'next/image';
import {Link} from '../../../navigation';

interface Props {
  product: SearchProduct;
}

export default function CartItem(props: Props) {
  const format = useFormatter();
  const {quantityInCart, addToCart, removeFromCart} = useProduct(props.product);

  return (
    <li className="flex space-x-4">
      <div className="relative flex items-center bg-white rounded py-2 overflow-hidden w-28 h-28 shrink-0 border border-greyscale-separator">
        <Link
          href={{pathname: '/shop/[id]', params: {id: props.product.slug!}}}
          className="no-underline"
        >
          <div className="relative h-28 w-28">
            <Image
              fill
              className="object-contain transition ease-in-out duration-500 hover:scale-125"
              src={props.product.imageUrl || ''}
              alt={props.product.name}
            />
          </div>
        </Link>
      </div>

      <div className="flex flex-col justify-between w-full">
        <div className="group cursor-pointer">
          <Link
            href={{pathname: '/shop/[id]', params: {id: props.product.slug!}}}
            className="no-underline"
          >
            <h3 className="text-base font-bold tracking-tight text-greyscale-body m-0 leading-5 group-hover:underline">
              {props.product.name}
            </h3>
            <p className="text-sm text-greyscale-label font-medium tracking-tight">
              {props.product.producer}
            </p>
          </Link>
        </div>

        <div className="flex justify-between items-center">
          <div>
            {!!props.product.discountedPrice && (
              <div className="text-sm line-through text-greyscale-label font-medium -mb-1">
                {format.number(props.product.price * quantityInCart, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            )}
            <div className="text-greyscale-body font-bold">
              {format.number(
                (props.product.discountedPrice || props.product.price) *
                  quantityInCart,
                {style: 'currency', currency: 'EUR'},
              )}
            </div>
          </div>

          <QuantityManager
            size="sm"
            quantity={quantityInCart}
            onAdd={addToCart}
            onSub={removeFromCart}
          />
        </div>
      </div>
    </li>
  );
}
