import { useTranslations } from 'next-intl';

interface Props {
  cities: { name: string }[];
  onCitySelect?: (city: string) => void;
}

export default function CitiesSection(props: Props) {
  const t = useTranslations('index');

  const sortByName = (a: {name: string}, b: {name: string}) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    return aName < bName ? -1 : (aName > bName ? 1 : 0);
  };

  return (
    <section className="py-20 bg-greyscale-gray-lighter" id="cities_section">
      <div className="section-container">
        <h2 className="text-3xl font-bold tracking-tight text-greyscale-title text-center mb-4">
          {t('CitiesSection.section-title')}
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {props.cities.sort(sortByName).map((city, idx) => (
            <div key={idx}>
              <div
                className="cursor-pointer bg-white hover:shadow-md hover:text-primary rounded-md border border-greyscale-gray-light"
                onClick={() =>
                  props.onCitySelect && props.onCitySelect(city.name)
                }
              >
                <div className="flex items-center justify-between px-6 py-3">
                  <span className="text-base">
                    {city.name}
                  </span>
                  <span className="before:content-arrow-right"/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
