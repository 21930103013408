'use client';

import {ReactElement, useRef} from 'react';
import type SwiperCore from 'swiper';
import {Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import type {Props as ListProps} from './List';
import {Breakpointable, ScreenSize} from './types';

interface Props<T> extends Omit<ListProps<T>, 'hasNextPage' | 'onLoadMore'> {
  columns?:
    | 'auto'
    | number
    | {
        xs?: 'auto' | number;
        sm?: 'auto' | number;
        md?: 'auto' | number;
        lg?: 'auto' | number;
        xl?: 'auto' | number;
        xxl?: 'auto' | number;
      };
  gap?: Breakpointable;
  renderHeader?: (args: {next: () => void; prev: () => void}) => ReactElement;
  renderFooter?: (args: {next: () => void; prev: () => void}) => ReactElement;
  autoPlay?: boolean;
  renderShowMoreItem?: () => React.ReactNode;
}

export default function ScrollableList<T extends unknown>({
  columns = 1,
  gap = 8,
  ...props
}: Props<T>) {
  const swiperRef = useRef<SwiperCore>();

  function getBreakpoints() {
    return {
      [ScreenSize.Xs]: {
        slidesPerView: typeof columns === 'object' ? columns?.xs : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.xs : gap) || 0) * 4,
      },
      [ScreenSize.Sm]: {
        slidesPerView: typeof columns === 'object' ? columns?.sm : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.sm : gap) || 0) * 4,
      },
      [ScreenSize.Md]: {
        slidesPerView: typeof columns === 'object' ? columns?.md : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.md : gap) || 0) * 4,
      },
      [ScreenSize.Lg]: {
        slidesPerView: typeof columns === 'object' ? columns?.lg : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.lg : gap) || 0) * 4,
      },
      [ScreenSize.Xl]: {
        slidesPerView: typeof columns === 'object' ? columns?.xl : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.xl : gap) || 0) * 4,
      },
      [ScreenSize.Xxl]: {
        slidesPerView: typeof columns === 'object' ? columns?.xxl : columns,
        spaceBetween: ((typeof gap === 'object' ? gap?.xxl : gap) || 0) * 4,
      },
    };
  }

  function prev() {
    swiperRef.current?.slidePrev();
  }

  function next() {
    swiperRef.current?.slideNext();
  }

  return (
    <div>
      {!!props.renderHeader && props.renderHeader({next, prev})}
      <Swiper
        modules={[Autoplay]}
        autoplay={props.autoPlay}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={getBreakpoints()}
      >
        {props.data.map((item, index) => (
          <SwiperSlide
            key={props.keyExtractor && props.keyExtractor(item, index)}
          >
            {props.renderItem(item, index)}
          </SwiperSlide>
        ))}
        {!!props.renderShowMoreItem && (
          <SwiperSlide className="!h-auto" key="showMoreItem">
            {props.renderShowMoreItem()}
          </SwiperSlide>
        )}
      </Swiper>
      {!!props.renderFooter && props.renderFooter({next, prev})}
    </div>
  );
}
