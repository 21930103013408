'use client';

import Header from '../../../../components/header/Header';
import { useWindowScroll } from '../../../../hooks';
import BannerDownloadApp from './BannerDownloadApp';

export default function HomeHeader() {
  const { isScrolledY } = useWindowScroll();
  return (
    <>
      <BannerDownloadApp />
      <Header
        className={
          !isScrolledY
            ? 'bg-greyscale-pink'
            : 'border-b border-greyscale-separator bg-white'
        }
      />
    </>
  );
}
