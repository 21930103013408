import { useTranslations } from 'next-intl';
import { Link } from '../../navigation';
import useNavlinks from './useNavLinks';

export default function Nav() {
  const t = useTranslations('common');
  const navLinks = useNavlinks();
  return (
    <nav className="hidden md:flex justify-center md:gap-6">
      {navLinks.map((item) => {
        return (
          <Link<any>
            key={item.label}
            href={item.href}
            className="text-center text-base font-medium tracking-tight text-greyscale-label py-3 no-underline hover:text-greyscale-title"
          >
            {t(item.label)}
          </Link>
        );
      })}
    </nav>
  );
}
