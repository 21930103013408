import { useTranslations } from 'next-intl';

export default function FooterSection() {
  const t = useTranslations('index');

  return (
    <section className="my-20">
      <div className="section-container">
        <div className="flex flex-wrap gap-8 justify-center">
          <div className="w-2/3 sm:w-1/4 text-center">
            <div className="mx-auto oblo">
              <i className="pe-7s-headphones"></i>
            </div>
            <p className="text-base font-medium tracking-tight text-greyscale-label mt-4 mb-0 p-0">
              {t('FooterSection.assistance')}
            </p>
          </div>
          <div className="w-2/3 sm:w-1/4 text-center">
            <div className="mx-auto oblo">
              <i className="pe-7s-credit"></i>
            </div>
            <p className="text-base font-medium tracking-tight text-greyscale-label mt-4 mb-0 p-0">
              {t('FooterSection.online-payments')}
            </p>
          </div>
          <div className="w-2/3 sm:w-1/4 text-center">
            <div className="mx-auto oblo">
              <i className="pe-7s-piggy"></i>
            </div>
            <p className="text-base font-medium tracking-tight text-greyscale-label mt-4 mb-0 p-0">
              {t('FooterSection.delivery')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
