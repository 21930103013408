import { Price } from '@/components/design-system/Price';
import { useTranslations } from 'next-intl';
import CheckoutButton from './CheckoutButton';

interface Props {
  totalPrice: number;
  closeMenu: () => void;
  isCartEmpty: boolean;
}

export default function Footer(props: Props) {
  const t = useTranslations('common');
  return (
    <div className="space-y-4 p-6">
      <div className="flex justify-between font-bold text-greyscale-title text-xl">
        <div className="text-lg">{t('DrawerCart.total')}</div>
        <Price price={props.totalPrice} />
      </div>
      <CheckoutButton
        closeMenu={props.closeMenu}
        isCartEmpty={props.isCartEmpty}
      />
    </div>
  );
}
