import ScrollableList from '@/components/design-system/ScrollableList';
import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

export const BlockQuote = ({text, author}) => {
  return (
    <div>
      <blockquote className={'relative flex justify-between h-full flex-col m0 mb-4 p-6 bg-white shadow-xl'}>
        <span className={'text-primary italic text-8xl leading-none absolute top-[15px] left-[-35px]'}>"</span>
        <p className="text-greyscale-gray-dark mb-2 text-sm font-normal">{text}</p>
        <div>
          <h5 className="text-greyscale-gray-darker font-medium">{author}</h5>
          <cite className="text-sm text-greyscale-gray font-normal not-italic align-middle">
            <span className="icon-head mr-1.5 mt-[-2px]"></span>
            @Facebook Review
          </cite>
        </div>
      </blockquote>
    </div>
  );
};

export default function TestimonialSection() {
  const t = useTranslations('index');

  const data: Array<{ href: string; image: string; }> = [
    {
      href: 'https://www.ilsole24ore.com/art/vino-domicilio-e-temperatura-giusta-startup-milanese-ora-sbarca-formentera-ACZWEgX',
      image: 'sole24ore.png',
    },
    {
      href: 'https://milano.corriere.it/notizie/cronaca/19_gennaio_07/vola-startup-winelivery-crowdfunding-11-milioni-nuove-aperture-e083ed90-128e-11e9-8e32-62f2e5130e0b.shtml',
      image: 'corriere.png',
    },
    {
      href: 'https://startupitalia.eu/103082-20190111-winelivery-raccoglie-piu-1-1-milioni-euro-crowdfundme',
      image: 'startup_italia.png',
    },
    {
      href: 'http://www.ansa.it/canale_terraegusto/notizie/vino/2019/01/22/vino-winelivery-raccoglie-12-milioni-da-crowdfunding_4fb4ba53-a01d-4eb9-9bb9-4c04a876f9c9.html',
      image: 'Ansa.png',
    },
    {
      href: 'http://vino.tv/it/winelivery-per-avere-il-vino-giusto-alla-temperatura-giusta-al-momento-giusto/',
      image: 'vinotv.png',
    },
    {
      href: 'https://ricerca.repubblica.it/repubblica/archivio/repubblica/2019/01/14/winelivery-raccoglie-altri-1-2-milioniAffari_e_Finanza23.html',
      image: 'laRepubblica.png',
    },
    {
      href: 'https://www.leggo.it/economia/news/winelivery_crowfunding-4217293.html',
      image: 'leggo.png',
    },
    {
      href: 'https://www.ilgiorno.it/milano/economia/winelivery-vino-domicilio-1.4674490',
      image: 'Ilgiorno.png',
    },
    {
      href: 'https://www.quotidiano.net/speciali/divino-sapore/winelivery-start-up-1.4655879',
      image: 'quotidiano.png',
    },
  ];

  return (
    <section className="bg-greyscale-gray-lighter py-20">
      <div className="section-container">
        <div className="flex flex-wrap md:flex-row flex-col gap-8 items-center">
          <div className="md:w-1/3 flex-1">
            <h2 className="text-3xl font-bold tracking-tight text-greyscale-title text-center md:text-left">
              {t('TestimonialSection.h4')}
            </h2>
          </div>
          <div className="md:w-2/3 flex-2">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-8 px-4">
              <BlockQuote
                text={
                  'Idea fantastica adatta per ogni occasione: dalla cena gourmet con amici o per una buona birra davanti alla televisione. Scelta ampissima di vini, champagne e birre artigianali, etichette ricercate e brand famosi. Da provare assolutamente: il mio ordine è arrivato in 20 minuti, birra fresca al punto giusto. Cheers!'
                }
                author={'VALERIO F.'}
              />
              <BlockQuote
                text={
                  'Molto soddisfatto! La migliore app per bere qualcosa di buono in soli 30 minuti! Per non parlare dei cocktail kit, il necessario per una festa in casa ben riuscita! =)'
                }
                author={'GIANMARCO M.'}
              />
              <BlockQuote
                text={
                  "Desiderare ardentemente una bottiglia di vino quando la cena è lì quasi pronta, scoprire l'esistenza di questa app, ordinare e in 20 minuti ricevere a casa la bottiglia tanto desiderata... evviva!"
                }
                author={'MARILIA S.'}
              />
              <BlockQuote
                text={
                  'I used winelivery and their service in august during my milan vacation and they saved my night out! We ordered cocktails and they came straight to our airbnb place in twenty min with icecubes, glasses and straws! Great service!'
                }
                author={'OLIVER G.'}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-12">
          <h4 className="text-2xl font-bold tracking-tight text-greyscale-body mb-4 p-0">
            {t('TestimonialSection.parlano-di-noi')}
          </h4>
          <ScrollableList
            autoPlay
            columns={{
              xxl: 5,
              xl: 5,
              lg: 4,
              md: 3,
              sm: 2,
              xs: 'auto',
            }}
            gap={8}
            data={data}
            keyExtractor={(elem, index) => index.toString()}
            renderItem={(elem) => (
              <a
                target="_blank"
                href={elem.href}
              >
                <Image
                  src={`${IMGPW + '/' + elem.image}`}
                  alt={elem.image}
                  width={110}
                  height={110}
                  className="opacity-75 m-auto"
                />
              </a>
            )}
          />
        </div>
      </div>
    </section>
  );
}
