'use client';

import { useEffect, useState } from 'react';
import useCart from '../../app/[locale]/shop/components/hooks/useCart';
import DrawerCart from '../drawers/DrawerCart';
import { Bag } from '../icons';
import { localize } from '@/actions/localization';
import useCartStore from '@/stores/cart/store';

function ButtonCart() {
  const [isOpen, setIsOpen] = useState(false);
  const { isEmpty, items, totalQuantity, removeProduct, reset } = useCart();

  useEffect(() => {
    localize().then((location) => {
      if (!location.location) {
        useCartStore.getState().emptyCart();
      }
    });
  }, []);

  return (
    <>
      <button
        className="flex items-center justify-center rounded-full text-greyscale-title h-10 w-10 relative"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Bag className="h-6 w-6 shrink-0" />
        {!isEmpty && (
          <div className="absolute top-0 right-0 flex items-center justify-center bg-primary text-white text-xs font-medium w-4 h-4 rounded-full">
            {totalQuantity}
          </div>
        )}
      </button>
      <DrawerCart
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
        items={items}
        deleteProduct={removeProduct}
      />
    </>
  );
}

export default ButtonCart;
