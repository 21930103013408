import { useTranslations } from 'next-intl';
import { CartItem } from '../../../stores/cart/types';
import CartItemComponent from './CartItem';

interface Props {
  items: CartItem[];
}

export default function CartList(props: Props) {
  const t = useTranslations('common');
  if (props.items.length === 0) {
    return (
      <div className="text-base text-greyscale-placeholder text-center">
        {t('DrawerCart.empty')}
      </div>
    );
  }

  return (
    <ul className="mb-0 pl-0 space-y-8">
      {props.items.map((item: CartItem) => (
        <CartItemComponent product={item.product} key={item.product.id} />
      ))}
    </ul>
  );
}
