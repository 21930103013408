import { useTranslations } from 'next-intl';
import { ChevronRight as IconChevronRight } from '../../../../../../../components/icons';

interface Props {
  predictions: google.maps.places.AutocompletePrediction[];
  onSelect: (prediction: google.maps.places.AutocompletePrediction) => void;
}

export default function Predictions({ predictions, onSelect }: Props) {
  const t = useTranslations('common');

  if (predictions.length === 0) {
    return null;
  }
  return (
    <div className="p-4 space-y-2">
      <h5 className="text-lg text-greyscale-title font-bold tracking-tighter m-0">
        {t('address.predictions')}
      </h5>
      <ul className="divide-y divide-greyscale-separator m-0 p-0">
        {predictions.map((place) => (
          <li
            key={place.place_id}
            className="cursor-pointer hover:bg-greyscale-background flex items-center py-3 -mx-4 px-4 text-greyscale-body"
            onClick={() => onSelect(place)}
          >
            <span className="flex-1 text-base text-font-medium tracking-tight leading-5 truncate">
              {place.description}
            </span>
            <IconChevronRight className="shrink-0 h-5" />
          </li>
        ))}
      </ul>
    </div>
  );
}
