'use client';

import dynamic from 'next/dynamic';
import { useState } from 'react';
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';
import ReactModal from 'react-modal';
import { DownloadAppContent } from './DownloadAppContent';

const IOsButton = dynamic(() => import('./IOsButton'), { ssr: false });
const AndroidButton = dynamic(() => import('./AndroidButton'), { ssr: false });
const DesktopButton = dynamic(() => import('./DesktopButton'), { ssr: false });

interface Props {
  desktopButtonTheme?: 'white' | 'primary';
  renderCustomDesktopButton?: (onClick: () => void) => React.ReactElement;
}

export default function DownloadAppButton(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenBanner = () => setIsOpen(true);
  const handleCloseBanner = () => setIsOpen(false);

  return (
    <>
      <BrowserView>
        <DesktopButton
          onClick={handleOpenBanner}
          palette={props.desktopButtonTheme || 'primary'}
          renderCustomButton={props.renderCustomDesktopButton}
        />
        <ReactModal
          style={{ overlay: { zIndex: 120 } }}
          isOpen={isOpen}
          shouldCloseOnOverlayClick={true}
          className="h-fit w-fit relative top-1/2 -translate-y-1/2 p-6 bg-white mx-auto rounded-md"
          overlayClassName="bg-black/50 h-screen fixed w-screen top-0 z-20"
          onRequestClose={handleCloseBanner}
        >
          <DownloadAppContent />
        </ReactModal>
      </BrowserView>
      <MobileView>
        {isIOS && <IOsButton />}
        {isAndroid && <AndroidButton />}
      </MobileView>
    </>
  );
}
