'use client';

import ScrollableList from '@/components/design-system/ScrollableList';
import {IMGPW} from '@/config';
import {Link} from '@/navigation';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import slugify from 'slugify';

interface Props {
  platinum?: any;
}

export default function BrandSection(props: Props) {
  const kebabCase = (str: string) => slugify(str, {lower: true});
  const t = useTranslations('index');

  return (
    <section className="py-12 container mx-auto">
      <div className="md:flex md:flex-row">
        <div className="px-4 mb-4 relative md:basis-1/2">
          <Image
            src={`${IMGPW}/brindisi_home.jpg`}
            alt="Having fun"
            fill
            style={{objectFit: 'contain'}}
          />
        </div>
        <div className='md:basis-1/2 px-4 xl:px-0'>
          <h2 className='text-3xl font-bold tracking-tight text-greyscale-title mb-2'>
            {t('BrandSection.h1-1')}
          </h2>
          <p className='text-base font-medium tracking-tight text-greyscale-label mb-2 p-0'>
            {t('BrandSection.p-1-1')}
          </p>
          <p className='text-base font-medium tracking-tight text-greyscale-label mb-2 p-0'>
            <Link href={{ pathname: '/shop', query: { category: 'wine' } }}>
              {t('BrandSection.p-2-1')}
            </Link>
            {t('BrandSection.p-2-2')}
          </p>
          <p className='text-base font-medium tracking-tight text-greyscale-label mb-2 p-0'>
            {t('BrandSection.p-3-1')}
            <Link
              href={{
                pathname: '/shop',
                query: {category: 'beer', subcategory: 'artisan'},
              }}
            >
              {t('BrandSection.p-3-2')}
            </Link>
            {t('BrandSection.p-3-3')}
            <Link
              href={{
                pathname: '/shop',
                query: {category: 'cocktails', subcategory: 'niococktails'},
              }}
            >
              {t('BrandSection.p-3-4')}
            </Link>
            {t('BrandSection.p-3-5')}
            <Link
              href={{
                pathname: '/shop',
                query: {category: 'cocktails', subcategory: 'cocktail-kit'},
              }}
            >
              {t('BrandSection.p-3-6')}
            </Link>
            {t('BrandSection.p-3-7')}
          </p>
          <p className='text-base font-medium tracking-tight text-greyscale-label mb-2 p-0'>
            {t('BrandSection.p-4-1')}
          </p>
        </div>
      </div>

      {props.platinum && props.platinum.length > 0 && (
        <div className='mt-20'>
          <h2 className='text-3xl font-bold tracking-tight text-greyscale-title text-center mb-2'>
            {t('BrandSection.brand-highlight')}
          </h2>

          <div className='mb-4'>
            <ScrollableList
              autoPlay
              columns={{
                xxl: 5,
                xl: 5,
                lg: 4,
                md: 3,
                sm: 2,
                xs: 'auto',
              }}
              gap={8}
              data={props.platinum}
              keyExtractor={(brand) => brand._id}
              renderItem={(platinum: any) => (
                <Link
                  key={platinum.tag}
                  href={{
                    pathname: '/partners/[slug]',
                    params: {slug: kebabCase(platinum.title)},
                  }}
                >
                  <Image
                    src={platinum.appBackgroundImageUrl}
                    alt={`partner_${platinum.tag}`}
                    style={{
                      width: '150px',
                      margin: 'auto',
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    fill
                  />
                </Link>
              )}
            />
          </div>
        </div>
      )}
    </section>
  );
}
